import { Box, Grid, OutlinedInput, Paper, Typography } from "@mui/material";
import React from "react";

export const OtpView = ({ setOtp, otp }: any) => {

    const handleChange = (event: any) => {
        setOtp(event.target.value);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Typography variant="body2" gutterBottom>
                            Check your email and enter the OTP
                        </Typography>
                        <OutlinedInput
                            id="outlined-adornment-otp"
                            type="text"
                            value={otp}
                            onChange={handleChange}
                        />
                    </Paper>
            </Grid>
        </Box>
    );
};
