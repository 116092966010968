import * as React from "react";
import { useState, useEffect } from "react";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Grid, ListItemText, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type props = {};

const HealthListScreen: React.FC<props> = (props) => {
  const params = useParams();
  const [ListData, setListData] = useState<Array<{}>>([]);

  useEffect(() => {
    const data = require(`../source/static/healthCategories/${params.id}.json`);

    if (data && data.length) {
      setListData(data);
    }
  }, [params]);

  return (<>
    {ListData.map((task: any) => (
      <Accordion key={task.id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{task.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <ListItemText primary="Category" secondary={task.category} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <ListItemText primary="Youtube Url" secondary={task.youtubeUrl} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <ListItemText primary="Description" secondary={task.description} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    ))}</>);
};

export default HealthListScreen;
