import { Alert, Snackbar } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { DoApicall } from "../service/serviceCalls";
import { useLocation } from "react-router-dom";
import axios from "axios";

const AppContext = createContext({});

export function useAppContext() {
  return useContext(AppContext) as any;
}

export const AppContextProvider: React.FC<any> = ({ children }: any) => {
  const [userDetails, setUserDetails] = useState({});
  const [state, setState] = useState<any>({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "success"
  });
  const { vertical, horizontal, open, message, severity } = state;
  const [appData, setAppData] = useState<any>({ events: [], jobs: [], projects: [] });
  const location = useLocation();

  const openToast = (newState: any) => {
    setState({ ...state, ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const renderSnackBar = () => {
    return (<Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      key={vertical + horizontal}
    ><Alert severity={severity}>{message}</Alert></Snackbar>)
  }

  const callAPISer = (apiName: any, payload: any, callBack?: any) => {
    DoApicall(apiName, appData, setAppData, payload, setUserDetails, callBack);
  }

  useEffect(() => {
    callAPISer(location.pathname, null);
  }, [location]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user_data') || '{}');

    if (user && user._id) {
      DoApicall("getMember", appData, setAppData, { memberId: user._id }, setUserDetails);
    }
    axios.interceptors.request.use(
      (config) => {
        // Before request: add authorization headers or log details
        return config;
      },
      (error) => {
        // Handle request errors
        console.error('Request Error:', error);
        return Promise.reject(error);
      }
    );

    // Response Interceptor
    axios.interceptors.response.use(
      (response) => {
        // After receiving response: you can transform data or log details
        console.log('Response:', response);
        if (response.status === 401 || response.status === 403) {
          window.location.href = '/login';
        }
        return response.data; // Directly return the data for easier handling
      },
      (error) => {
        // Handle response errors
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Response Error:', error.response);
          if (error.response.status === 401) {
            window.location.href = '/login';
            // Handle specific error codes, like unauthorized (401)
            console.warn('Unauthorized! Redirecting to login...');
            // Redirect to login or handle token refresh
          }
        } else if (error.request) {
          // No response received from the server
          console.error('No response received:', error.request);
        } else {
          // Error setting up the request
          console.error('Error:', error.message);
        }
        return Promise.reject(error);
      }
    );
  }, [])

  return (
    <AppContext.Provider value={{ userDetails, setUserDetails, openToast, appData, callAPISer }}>
      {children}
      {renderSnackBar()}
    </AppContext.Provider>
  );
}
