import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import ResponsiveAppBar from "./components/appBar";
import AptitudeScreen from "./screens/aptitude";
import "./App.css";
import QuestionsListScreen from "./screens/questionsList";
import HomeScreen from "./screens/home";
import QuizScreen from "./screens/quiz";
import ProgrammingScreen from "./screens/programming";
import ProgrammingListScreen from "./screens/programmingList";
import EducationScreen from "./screens/education";
import CompanyCatgoriesScreen from "./screens/companyCatgories";
import CompanyListScreen from "./screens/compantList";
import FreelanceTaskScreen from "./screens/freelance";
import HealthCatgoriesScreen from "./screens/healthCategories";
import HealthListScreen from "./screens/healthList";
import { AppContextProvider } from "./provider/appProvider";
import SpeechRating from "./views/speechRatingView";
import { LoginPage } from "./screens/login";
import { QuizListScreen } from "./screens/quizList";
import AppsScreen from "./screens/apps";

const theme = createTheme({
  palette: {
    primary: {
      main: '#6cb4b8',
    },
    secondary: {
      main: "#FFFFFF"
    }
  },
});

function App() {
  const initialRoute = localStorage.getItem("token") ? "/home" : "/login";

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <ResponsiveAppBar title="Fair Pirates" />
          <Routes >
            <Route path="/" element={<Navigate to={initialRoute} />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/apps" element={<AppsScreen />} />
            <Route path="/quiz" element={<QuizListScreen />} />
            <Route path="/education" element={<EducationScreen />} />
            <Route path="/education/aptitudes" element={<AptitudeScreen />} />
            <Route path="/education/aptitudes/:id" element={<QuestionsListScreen />} />
            <Route path="/education/programming" element={<ProgrammingScreen />} />
            <Route path="/education/speechRating" element={<SpeechRating />} />
            <Route path="/education/programming/:id" element={<ProgrammingListScreen />} />
            <Route path="/education/aptitude_quiz" element={<QuizScreen />} />
            <Route path="/apps/quiz" element={<QuizScreen />} />
            <Route path="/companies" element={<CompanyCatgoriesScreen />} />
            <Route path="/companies/companycatgories/:id" element={<CompanyListScreen />} />
            <Route path="/health" element={<HealthCatgoriesScreen />} />
            <Route path="/health/healthcatgories/:id" element={<HealthListScreen />} />
            <Route path="/freelance" element={<FreelanceTaskScreen />} />
          </Routes>
        </AppContextProvider>
      </ThemeProvider>
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
    </div>
  );
}

export default App;
