import * as React from "react";
import { useState, useEffect } from "react";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import CustomDataTable from "../components/table/customTable";
import { useParams } from "react-router-dom";

type props = {};

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", sortable: true, width: 80 },
  { field: "name", headerName: "Name", sortable: true, width: 200 },
  { field: "places", headerName: "Places", sortable: true, width: 130 },
];

const CompanyListScreen: React.FC<props> = (props) => {
  const params = useParams();
  const [ListData, setListData] = useState<Array<{}>>([]);

  useEffect(() => {
    const data = require(`../source/static/companyCategories/${params.id}.json`);

    if (data && data.length) {
      setListData(data);
    }
  }, [params]);

  return <CustomDataTable pageSize={10} rows={ListData} columns={columns} />;
};

export default CompanyListScreen;
