import * as React from "react";
import { Folder } from "@mui/icons-material";
import {
  ListItemButton,
  List,
  ListItemAvatar,
  ListItemText,
  Box,
  Avatar,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

type props = { dataProvider: Array<{ name: String; id: Number }>, title: string, path: string };

const CustomListView: React.FC<props> = ({ dataProvider, title, path }) => {
  const navigate = useNavigate();

  const generate = (dataProvider: Array<{ name: String; id: Number }> = []) => {
    return dataProvider.map((value) => (
      <ListItemButton
        onClick={() => {
          navigate(`${path}/${value.name.toLowerCase().replaceAll(" ", "")}`);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <Folder />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={value.name} />
      </ListItemButton>
    ));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 1, mb: 1, ml: 2 }} variant="h6" component="div">
            {title}
          </Typography>
          <List>{generate(dataProvider)}</List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomListView;
