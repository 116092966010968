import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QuestionsListView from "../views/questionsListView";

type props = {};

const ProgrammingListScreen: React.FC<props> = (props) => {
  const params = useParams();
  const [questionsListData, setQuestionsListData] = useState<
    Array<Question2>
  >([]);

  useEffect(() => {
    const data = require(`../source/static/programming/${params.id}.json`);

    if (data && data.length) {
      setQuestionsListData(data);
    }
  }, [params]);

  return <QuestionsListView dataProvider={questionsListData} />;
};

export default ProgrammingListScreen;
