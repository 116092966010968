import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import GoogleLoginButton from '../components/googleSign';
import FP from "../assets/fp.png";
import { Image } from '@mui/icons-material';
import GooglePayButton from '../components/googlepay';

export const LoginFormView = (props: any) => {

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div style={{ width: '100%', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Image className='boxShadow' component={Avatar} style={{ cursor: "pointer" }} alt="fairpirates logo" src={FP} sx={{ width: '80%', height: '80%' }} onClick={() => {
                    (window as any).cookieStore.set('g_state', {}).then(() => {
                        ((window as any).google as any).accounts.id.prompt();
                    })
                }} />
            </div>
            <GoogleLoginButton disbleButton handleSuccess={(data: any) => { props.handleSubmit && props.handleSubmit(data) }} />
            <GooglePayButton />
        </Box>
    );
}