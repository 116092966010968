import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ShipImage from "../assets/ship.jpeg";

export const LeftView = ({ children }: any) => {

    return (
        <Grid container component="main" sx={{ height: 'calc(100vh - 60px)' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(' + ShipImage + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />  
            <Grid item style={{ display: "flex", alignItems: "center", justifyContent: "center" }} xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                {children}
            </Grid>
        </Grid>
    );
}