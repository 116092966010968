import * as React from 'react';
import { useAppContext } from '../provider/appProvider';
import { useNavigate } from 'react-router-dom';
import { LeftView } from '../views/leftView';
import { LoginFormView } from '../views/login';
import { AxiosCall } from '../service/apiCall';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { OtpView } from '../views/otpView';

export const LoginPage = () => {
    const { setUserDetails, openToast, callAPISer } = useAppContext();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = React.useState(false);
    const userData = React.useRef({ email: "", name: "" });
    const [otp, setOtp] = React.useState("");

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    React.useEffect(() => {
        const appEle: any = document.getElementsByClassName('App');

        if (appEle.length > 0) {
            appEle[0].style.height = 'calc(100% - 0px)';
            appEle[0].style.overflow = 'hidden';
        }

        return () => {
            appEle.length && (appEle[0].style = {});
        }
    }, []);

    const handleSubmit = (values: any) => {
        userData.current = values;
        AxiosCall("post", {
            email: values.email, name: values.name
        }, "qweb/sendOtp", (res: any) => {
            if (res.result) {
                setOpenDialog(true);
                localStorage.setItem('user_data', JSON.stringify(res.result));
                setUserDetails(res.result);
            } else {
                openToast({ message: "Server Error!" });
            }
        }, () => {
            openToast({ message: "Server Error!" });
        })
    }

    const handleVerify = () => {
        callAPISer("signin", { email: userData.current.email, name: userData.current.name, otp: otp.trim() }, (res: any) => {
            setOpenDialog(false);
            openToast({ message: "Success!" });
            navigate('/home');
        });
    }

    return (
        <LeftView>
            <LoginFormView initialValues={userData.current} handleSubmit={handleSubmit} />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>One Time Password</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: 10 }}>
                    <OtpView setOtp={setOtp} otp={otp} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleVerify} color="primary">
                        Verify
                    </Button>
                </DialogActions>
            </Dialog>
        </LeftView>
    );
}