import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Button, CardActions, CardHeader } from '@mui/material';
import { useAppContext } from '../../provider/appProvider';
import { EmojiEvents } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export const CustomCard = ({ _id, title, description, onClick, participants1, startAt = "", endAt = "" }: any) => {
    const navigate = useNavigate();
    const partNum = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
        participants1 ? String(participants1).trim().split(" ").length - 1 : 0
    );
    const { userDetails, callAPISer, openToast } = useAppContext();
    const [actionLabel, setActionLabel] = React.useState("Join");

    React.useEffect(() => {
        if (userDetails.events && userDetails.events.includes(_id)) {
            setActionLabel("Joined " + partNum);
        } else if (moment(startAt) <= moment() && moment() <= moment(endAt)) {
            setActionLabel("Attend");
        } else if (endAt && moment() >= moment(endAt)) {
            setActionLabel("Finished");
        } else {
            setActionLabel("Join");
        }
    }, [userDetails.events, _id, partNum]);

    const handleonDelete = () => {
        callAPISer("deleteEvent", { _id }, () => { openToast({ message: "Event Deleted!!" }); });
    }

    const handleOnClick = () => {
        if (actionLabel === "Attend") {
            navigate("/apps/quiz");
        } else
            onClick && onClick();
    }

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe">
                        <EmojiEvents />
                    </Avatar>
                }
                title={title}
                subheader={`${startAt} - ${endAt}`}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button disabled={actionLabel !== "Join" && actionLabel !== "Attend"} size="small" color={"primary"} onClick={handleOnClick}>
                    {actionLabel}
                </Button>
                {userDetails.isAdmin && <Button size="small" color={"primary"} onClick={handleonDelete}>
                    Delete
                </Button>}
            </CardActions>
        </Card>
    );
}
