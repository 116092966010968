import React, { useEffect, useState } from 'react';
import { AxiosCall } from '../../service/apiCall';

const GooglePayButton = () => {
    const [gpayBtn, setGpayBtn] = useState<any>(null);

    const onGooglePayButtonClick = async () => {
        const paymentsClient = new (window as any).google.payments.api.PaymentsClient({ environment: 'TEST' });

        const paymentDataRequest = {
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
                // {
                //     type: 'CARD',
                //     parameters: {
                //         allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                //         allowedCardNetworks: ['MASTERCARD', 'VISA'],
                //     },
                //     tokenizationSpecification: {
                //         type: 'PAYMENT_GATEWAY',
                //         parameters: {
                //             gateway: 'example',
                //             gatewayMerchantId: 'exampleGatewayMerchantId',
                //         },
                //     },
                // },
                {
                    type: 'UPI',
                    parameters: {
                      pa: 'rsankarganesheee@okicici', // Replace with your UPI ID
                      pn: 'Fair Pirates', // Your name or business name
                    },
                    tokenizationSpecification: {
                      type: 'DIRECT', // Use 'DIRECT' for UPI
                    },
                  },
            ],
            merchantInfo: {
                merchantId: 'BCR2DN4TWX52V4YY',
                merchantName: 'Fair Pirates',
            },
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPrice: '1.00',
                currencyCode: 'INR',
            },
        };

        try {
            const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);

            // Send payment data to your backend for processing
            AxiosCall("post", paymentData, "qweb/paymentHook", () => {

            })

        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const paymentsClient = new (window as any).google.payments.api.PaymentsClient({ environment: 'TEST' });

        const button = paymentsClient.createButton({
            onClick: onGooglePayButtonClick,
            buttonColor: '#6cb4b8',
            buttonType: 'short',
        });

        setGpayBtn(button)

        onGooglePayButtonClick();

    }, []);

    return <div id="google-pay-button-container" dangerouslySetInnerHTML={{ __html: gpayBtn && gpayBtn.innerHTML }}></div>;
};

export default GooglePayButton;
