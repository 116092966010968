import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import { translate } from "../utils";

type props = {};

const QuestionsListView: React.FC<QuestionProps2> = ({ dataProvider }) => {
  const fixText = (id: string, text: string) => {
    const ele = document.getElementById(id);

    if (ele) {
      if (text.includes("<p>")) ele.innerHTML = text;
      else ele.innerText = text;
    }
    return "";
  };

  const renderHtmlText = (id: string, text: string="") => {
    return <div id={id} dangerouslySetInnerHTML={{ __html: text }}></div>;
  };

  const renderCards = (dataProvider: Array<Question2> = []) => {
    return dataProvider.map((obj, index) => (
      <Card sx={{ width: "100%" }} style={{ margin: "5px 0 5px 0" }}>
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
            <b>Question: </b>
            {renderHtmlText(`fp-quest-${index}`, obj.question)}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <b>Answer: </b>
            {obj.answer}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <b>Explanation: </b> {renderHtmlText(`fp-explan-${index}`, obj.explanation )}
          </Typography>
        </CardContent>
      </Card>
    ));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid style={{ padding: 10 }}>
        {/* <Grid item xs={12} md={6}> */}
        {renderCards(dataProvider)}
        {/* </Grid> */}
      </Grid>
    </Box>
  );
};

export default QuestionsListView;
