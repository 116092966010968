import React, { useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Paper,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import { useAppContext } from "../provider/appProvider";

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const QuizView = ({ dataProvider, onFinish }: QuestionProps2) => {
  const { openToast } = useAppContext();
  const classes = useStyles();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const selectedAnswers = useRef<any>({});
  const answerSubmitted = useRef<any>(false);
  const queOption = dataProvider[currentQuestion].options || [];

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const temp = { ...selectedAnswers.current };
    const reslt = dataProvider[currentQuestion].answer === value;

    if (reslt !== temp["is" + currentQuestion]) {
      temp["total"] = temp["total"] ? temp["total"] + (reslt ? 1 : -1) : reslt ? 1 : 0;
    }
    temp["is" + currentQuestion] = reslt;
    temp[currentQuestion] = value;
    selectedAnswers.current = temp;
    setSelectedAnswer(event.target.value);
    setEnableSubmit(true);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer === "") {
      openToast({ message: "Please select an answer before moving to the next question.", severity: "info" });
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswer(selectedAnswers.current[currentQuestion + 1] || "");
      if (!selectedAnswers.current[currentQuestion + 1])
        setEnableSubmit(false);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion === 0) {
      openToast({ message: "This is the first question.", severity: "info" });
    } else {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedAnswer(selectedAnswers.current[currentQuestion - 1] || "");
      setEnableSubmit(true);
    }
  };

  const handleFinishQuiz = () => {
    answerSubmitted.current = true;
    setEnableSubmit(false);
    onFinish && onFinish({ correctAnswers: selectedAnswers.current.total, questions: dataProvider.map((obj: any) => ({ answer: obj.answer, question: obj.question })) });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              {currentQuestion + 1}. {dataProvider[currentQuestion].question}
            </Typography>
            {queOption.length && (
              <RadioGroup value={selectedAnswer} onChange={handleAnswerChange}>
                {queOption.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={<Radio color="primary" />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            )}
            <div className="flex-center gap-10">
              <Button
                variant="contained"
                disabled={currentQuestion === 0}
                color="primary"
                onClick={handlePreviousQuestion}
              >
                Previous
              </Button>
              {currentQuestion < dataProvider.length - 1 ? (
                <Button
                  variant="contained"
                  disabled={!enableSubmit}
                  color="primary"
                  onClick={handleNextQuestion}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={!enableSubmit && answerSubmitted.current}
                  color="primary"
                  onClick={handleFinishQuiz}
                >
                  Finish
                </Button>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuizView;
