import React, { useState } from "react";
import { Box, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

interface PopupProps {
    title: string;
    content: React.ReactNode;
    onClose: any;
    dialogActionsLabel: any
}

const CustomDialog: React.FC<PopupProps> = ({ title, content, onClose, dialogActionsLabel }) => {

    const handleClose = (label: any) => {
        onClose(label);
    };

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{title}</Typography>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                {dialogActionsLabel.map((label: any, index: number) => (
                    <Button
                        key={index}
                        variant="contained"
                        onClick={() => handleClose(label)}
                        sx={{ margin: "0 8px" }}
                    >
                        {label}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
