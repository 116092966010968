import React, { useEffect } from "react";
import { Button } from "@mui/material";

interface props {
  disbleButton?: boolean;
  handleSuccess?: Function;
}

const GoogleLoginButton = ({ disbleButton, handleSuccess }: props) => {
  useEffect(() => {
    // Load the Google Sign-In API script dynamically
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize the Google Sign-In API
      ((window as any).google as any).accounts.id.initialize({
        client_id:
          "120908019847-1dq33rr6rhgdigid7qel0nmakc7onlb9.apps.googleusercontent.com",
        callback: handleSignIn,
        //  dataType: 'CredentialResponse'
        // CredentialResponse
        // auto_select: true,
      });
    };
  }, []);

  const handleSignIn = (response: any) => {
    function decodeJwtResponse(token: any) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }

    handleSuccess && handleSuccess(decodeJwtResponse(response.credential));
  };

  if (disbleButton) {
    return <></>;
  } else {
    return (
      <Button
        variant="contained"
        onClick={() => ((window as any).google as any).accounts.id.prompt()}
      >
        Sign in with Google
      </Button>
    );
  }
};

export default GoogleLoginButton;
