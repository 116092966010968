import * as React from 'react';
import { QuizList } from '../views/quizListView';
import { useAppContext } from '../provider/appProvider';

export const QuizListScreen = () => {
    const { appData, callAPISer } = useAppContext();

    const handleClick = (data: any) => {
        callAPISer("update", data);
    }

    return (
        <QuizList dataProvider={appData.events} onClick={handleClick} />
    );
}
