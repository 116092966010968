import * as React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

export default function CustomDataTable({ rows, columns, pageSize }: CustomDataTable) {
  return (
    <div style={{ height: 'auto', width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationModel={{ page: 0, pageSize: pageSize }}
      />
    </div>
  );
}
