import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Card, CardContent } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../provider/appProvider";

function RenderCard({
  title,
  description,
  redirectUrl,
  navigate,
  onClick,
  icon,
}: any) {
  const classes = useStyles();
  const { setUserDetails } = useAppContext();
  const CustomIcon = icon;

  return (
    <Card style={{ cursor: "pointer", minHeight: 200 }} onClick={onClick}>
      <CardContent>
        <CustomIcon />
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        <Typography
          textOverflow="ellipsis"
          variant="body2"
          component="p"
          className={classes.desText}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme: any) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(5, 0, 4),
  },
  cardGrid: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(8),
  },
  desText: {
    color: theme.palette.primary.main,
  },
}));

const HomeView = ({ dataProvider, title }: HomeViewProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {title && (
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              {title}
            </Typography>
          </Container>
        </div>
      )}
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          {dataProvider.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <RenderCard
                navigate={navigate}
                onClick={() => {
                  navigate(item.redirectUrl || "");
                }}
                {...item}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default HomeView;
