import * as React from "react";
import { getRandomQuestions, getRandomQuestionsForDynamic } from "../utils";
import QuizView from "../views/quizView";
import { useAppContext } from "../provider/appProvider";

const QuizScreen = () => {
  const [dataProvider, setDataProvider] = React.useState(getRandomQuestionsForDynamic());
  const { callAPISer, userDetails } = useAppContext();

  const handleFinish = (data: any) => {
    if (data) {
      callAPISer('saveAnswer', { memberId: userDetails._id, ...data });
    }
  }

  return <QuizView dataProvider={dataProvider} onFinish={handleFinish} />;
};

export default QuizScreen;
