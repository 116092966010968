import * as React from 'react';
import { CustomCard } from '../components/card/card';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAppContext } from '../provider/appProvider';

export const QuizList = ({ dataProvider, onClick }: any) => {
    const [event, setEvent] = React.useState<any>({});
    const [openDialog, setOpenDialog] = React.useState(false);
    const { callAPISer, userDetails } = useAppContext();

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleAddEvent = () => {
        callAPISer("addEvent", event);
        handleCloseDialog();
    }

    const handleJoin = (obj: any) => {
        onClick({ ...obj, key: "events", value: (userDetails.events || "") + " " + obj._id, memberId: userDetails._id, createdAt: new Date() });
    }
    return (
        <Container>
            <Typography variant="h6" gutterBottom>
                Quiz Events   {userDetails.isAdmin && <Tooltip title="Add Task" aria-label="add-task">
                    <IconButton color="primary" onClick={handleOpenDialog}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>}
            </Typography>
            <Grid container spacing={4}>
                {Boolean(dataProvider) ?
                    dataProvider.map((obj: any, index: number) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                            <CustomCard {...obj} onClick={() => { handleJoin(obj) }} />
                        </Grid>
                    )) : <Typography variant="h6" gutterBottom>No Events Now</Typography>
                }
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>Add Event</DialogTitle>
                    <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: 10 }}>
                        <TextField
                            required
                            label="Category"
                            fullWidth
                            value={event.category}
                            onChange={(e) => setEvent({ ...event, category: e.target.value })}
                        />
                        <TextField
                            required
                            label="Title"
                            fullWidth
                            value={event.title}
                            onChange={(e) => setEvent({ ...event, title: e.target.value })}
                        />
                        <TextField
                            required
                            label="Description"
                            multiline
                            rows={4}
                            fullWidth
                            value={event.description}
                            onChange={(e) => setEvent({ ...event, description: e.target.value })}
                        />
                        <TextField
                            required
                            label="Amount"
                            multiline
                            fullWidth
                            value={event.payment}
                            onChange={(e) => setEvent({ ...event, amount: e.target.value })}
                        />
                        <TextField
                            required
                            label="Youtube Url"
                            multiline
                            fullWidth
                            value={event.youtubeUrl}
                            onChange={(e) => setEvent({ ...event, youtubeUrl: e.target.value })}
                        />
                        <TextField
                            required
                            label="Start date"
                            placeholder='2024/01/01 12:30:00'
                            multiline
                            fullWidth
                            value={event.startAt}
                            onChange={(e) => setEvent({ ...event, startAt: e.target.value })}
                        />
                        <TextField
                            required
                            label="End date"
                            placeholder='2024/01/01 12:30:00'
                            multiline
                            fullWidth
                            value={event.endAt}
                            onChange={(e) => setEvent({ ...event, endAt: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleAddEvent} color="primary">
                            Add Event
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Container>
    );
}
