import React, { useState, useRef } from 'react';
import { Button, Typography, Box, TextField } from '@mui/material';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import { Mic, Speaker, VolumeUp } from '@mui/icons-material';
import TwoFriend from "../source/conversation/twoFriend.json"

const SpeechToText = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcribedText, setTranscribedText] = useState('');
  const recognitionRef = useRef<any>(null);
  const [messages, setMessages] = useState<any>(TwoFriend);
  const [grade, setGrade] = useState<any>('');
  const [unMatched, setUnMatched] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleSpeech = (message: any) => {
    if (!('speechSynthesis' in window)) {
      alert('Your browser does not support the Web Speech API');
      return;
    }

    const utterance = new SpeechSynthesisUtterance(message);
    utterance.lang = 'en-US';
    window.speechSynthesis.speak(utterance);
  };

  const evaluateSpeech = (targetSentence: any, spokenText: any) => {
    const targetWords = targetSentence.toLowerCase().replace(/[.,!?]/g, '').split(' ');
    const spokenWords = spokenText.toLowerCase().replace(/[.,!?]/g, '').split(' ');
    const tempUnMatched: any = [];

    // Count the number of matching words
    const matchedWords = spokenWords.filter((word: any) => {
      return targetWords.includes(word);
    }).length;
    targetWords.filter((word: any) => {
      if (!spokenWords.includes(word)) tempUnMatched.push(word)
      return spokenWords.includes(word);
    })
    const accuracy = (matchedWords / targetWords.length) * 100;

    // Assign a grade based on accuracy
    if (accuracy > 90) setGrade("A");
    else if (accuracy > 75) setGrade("B");
    else if (accuracy > 50) setGrade("C");
    else setGrade("D");

    console.log(targetSentence, spokenText, tempUnMatched)

    setUnMatched(tempUnMatched);
  };

  const startRecording = (txt: any, index: number) => {
    if (!('webkitSpeechRecognition' in window)) {
      alert('Your browser does not support the Web Speech API');
      return;
    }

    setUnMatched([]);
    const SpeechRecognition: any = window.webkitSpeechRecognition;

    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = false;
    recognitionRef.current.lang = 'en-US';

    recognitionRef.current.onstart = () => {
      setIsRecording(true);
    };

    recognitionRef.current.onresult = (event: any) => {
      const transcript = Array.from(event.results)
        .map((result: any) => result[0])
        .map(result => result.transcript)
        .join('');

      setTranscribedText(transcript);
      evaluateSpeech(txt, transcript);
    };

    recognitionRef.current.onerror = (event: any) => {
      console.error('Speech recognition error', event.error);
    };

    recognitionRef.current.onend = () => {
      setIsRecording(false);
    };

    recognitionRef.current.start();
    setSelectedIndex(index);
  };

  const stopRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    setIsRecording(false);
  };

  return (
    <Box sx={{ margin: 'auto', padding: 2, background: "white" }}>
      <Typography variant="h6" gutterBottom>
        Conversation
      </Typography>
      <List sx={{ overflow: 'auto', marginBottom: 2 }}>
        {messages.map((message: any, index: any) => (
          <>
            <ListItem key={index} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar>{String(message.speaker).slice(0, 1)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={message.message}
                secondary={message.speaker}
                sx={{
                  borderRadius: 1,
                  p: 1,
                }}
              > </ListItemText>
              <Button onClick={() => handleSpeech(message.message)}>
                <VolumeUp />
              </Button>
              <Button onClick={isRecording ? stopRecording : () => startRecording(message.message, index)}>
                <Mic />
              </Button>
            </ListItem>
            {index === selectedIndex && <ListItemText color='primary' primary={"Result: " + grade} secondary={"Missed Words:  " + unMatched.join(",")} sx={{
              borderRadius: 1,
              p: 1,
            }} />}
          </>
        ))}
      </List >
    </Box>
  );
};

export default SpeechToText;