import axios from "axios";

export const getConvertedQuestion = ({ dataProvider }: QuestionProps) => {
  const tempData: Array<Question> = [];

  if (!dataProvider || !dataProvider.forEach) {
    return [];
  } else if (
    dataProvider &&
    dataProvider.length &&
    !dataProvider[0].answer.includes("#")
  ) {
    return dataProvider;
  }

  dataProvider.forEach((obj, index) => {
    const tempObj1: any = obj;
    const ranNo = Math.floor(Math.random() * 5) + 1;

    obj.variables.forEach((str, index) => {
      tempObj1.question = tempObj1.question.replaceAll(`#${index + 1}`, str);
      tempObj1.code = tempObj1.code.replaceAll(`#${index + 1}`, str);
      tempObj1.explanation = tempObj1.explanation.replaceAll(
        `#${index + 1}`,
        str
      );
    });
    
    eval(tempObj1.code); // after executed , result will be stored in window
    (window as any).result = Number((window as any).result.toFixed(2));
    tempObj1.options = [];
    const onePercent = (window as any).result / 100;

    [1, 2, 3, 4, 5].forEach((no) => {
      if (ranNo === no) {
        tempObj1.options.push(
          tempObj1.answer.replaceAll(`#ans`, (window as any).result)
        );
      } else if (ranNo > no) {
        const val = onePercent * no;

        tempObj1.options.push(
          tempObj1.answer.replaceAll(`#ans`, (window as any).result + val)
        );
      } else {
        const val = onePercent * no;

        tempObj1.options.push(
          tempObj1.answer.replaceAll(`#ans`, (window as any).result + val)
        );
      }
    });
    tempObj1.answer = tempObj1.answer.replaceAll(
      `#ans`,
      (window as any).result
    );
    tempObj1.explanation = tempObj1.explanation.replaceAll(
      `#ans`,
      (window as any).result
    );
    if (tempObj1.explanation.includes("{")) {
      tempObj1.explanation.split("{").forEach((tempStr: any) => {
        if (tempStr.includes("}")) {
          const sptStr = tempStr.slice(0, tempStr.indexOf("}"));
          const sptAns = eval(sptStr);
          const repStr = `{${sptStr}}`;

          tempObj1.explanation = tempObj1.explanation.replaceAll(
            repStr,
            sptAns
          );
        }
      });
    }
    if (tempObj1.nameVariables) {
      tempObj1.nameVariables.forEach((str: any, index: any) => {
        tempObj1.question = tempObj1.question.replaceAll(`#N${index + 1}`, str);
        tempObj1.explanation = tempObj1.explanation.replaceAll(`#N${index + 1}`, str);
      })
    }

    tempData.push(tempObj1);
  });

  return tempData;
};

export const getRandomQuestions = () => {
  const listOfTopics = require(`../source/aptitudeTopics.json`);
  const dataProvider: any = [];

  listOfTopics.forEach((obj: any) => {
    const data = require(`../source/static/aptitude/${obj.name
      .toLowerCase()
      .replaceAll(" ", "")}.json`);
    const ranNo = Math.floor(Math.random() * data.length - 1) + 1;

    dataProvider.push(data[ranNo]);
  });

  return dataProvider;
};

export const getRandomQuestionsForDynamic = () => {
  const listOfTopics = require(`../source/aptitudeTopics.json`);
  const dataProvider: any = [];

  listOfTopics.forEach((obj: any) => {
    const data = require(`../source/dynamic/${obj.name
      .toLowerCase()
      .replaceAll(" ", "")}.json`);
    const ranNo = Math.floor(Math.random() * data.length - 1) + 1;

    data &&
      data.length &&
      dataProvider.push(
        getConvertedQuestion({ dataProvider: [data[ranNo]] })[0]
      );
  });

  return dataProvider;
};

export const translate = (text: string) => {
  const encodedParams = new URLSearchParams();
  encodedParams.append("q", text);
  encodedParams.append("target", "ta");
  encodedParams.append("source", "en");
  
  const options = {
    method: 'POST',
    url: 'https://google-translate1.p.rapidapi.com/language/translate/v2',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      'Accept-Encoding': 'application/gzip',
      'X-RapidAPI-Key': '86af2dcdcemsh87d2d1ea8df1f9fp17e6aajsne854a402b0a7',
      'X-RapidAPI-Host': 'google-translate1.p.rapidapi.com'
    },
    data: encodedParams
  };
  
  axios.request(options).then(function (response: any) {
    console.log(response.data);
  }).catch(function (error: any) {
    console.error(error);
  });
}
