import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import ContactSupport from "@mui/icons-material/ContactSupport";

const linkDetails = [
  {
    displayName: "Contact US",
    href: "mailto:piratesfair@gmail.com",
    icon: ContactSupport,
  },
];

const useStyles = makeStyles((theme: any) => ({
  NavBar: {
    backgroundColor: "#400CCC",
    paddingRight: "79px",
    paddingLeft: "5px",
    marginBottom: "100px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  logoText: {
    fontFamily: "Dancing Script, cursive",
    fontWeight: 500,
    color: "#FFFEFE",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
  secondary: {
    color: theme.palette.secondary.main
  }
}));

const ResponsiveAppBar: React.FC<{ title: string }> = ({ title }) => {
  const classes = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    // retrieveData();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  // const retrieveData = () => {
  //   axios({
  //     method: "post",
  //     url: "https://api.openai.com/v1/chat/completions?model=gpt-3.5-turbo",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization:
  //         "Bearer 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",
  //     },
  //     data: {
  //       model: "gpt-3.5-turbo",
  //       messages: [
  //         {
  //           role: "user",
  //           content:
  //             "generate 10 aptitude questions and answer and explanation related to Data Interpretation topic as JSON format",
  //         },
  //       ],
  //     },
  //   }).then((res) => {
  //     if (res.data.choices) {
  //       const cont = res.data.choices[0].message.content || "";

  //       data.current.str = data.current.str + cont;
  //       if (data.current.count !== 5) {
  //         retrieveData();
  //         data.current.count = data.current.count + 1;
  //       } else {
  //         console.log("data.current.str", data.current.str);
  //       }
  //     }
  //   });
  // };

  const desktopView = () => {
    return (
      <Toolbar className={classes.toolbar}>
        <div style={{ display: "flex", alignItems: "center", color: "white" }}>
          {Title}
        </div>
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const viewMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon color="secondary" />
        </IconButton>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={classes.drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div style={{ display: "flex", alignItems: "center", color: "white" }}>
          {Title}
        </div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return linkDetails.map(({ displayName, href, icon }) => {
      const CustomIcon = icon;

      return (
        <Link
          {...{
            color: "inherit",
            style: {
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            },
            key: displayName,
          }}
          href={href}
        >
          <CustomIcon />
          <MenuItem style={{ color: mobileView ? "" : "white" }}>{displayName}</MenuItem>
        </Link>
      );
    });
  };

  const Title = (
    <Typography
      style={{ fontSize: "1.15rem", color: "white" }}
      variant="h6"
      component={mobileView ? "h6" : "h1"}
    >
      {title}
    </Typography>
  );

  const getMenuButtons = () => {
    return linkDetails.map(({ displayName, href }) => {
      return (
        <Button
          {...{
            key: displayName,
            // to: href,
            // component: RouterLink,
            className: classes.menuButton,
          }}
          style={{color: "white"}}
          href={href}
        >
          {displayName}
        </Button>
      );
    });
  };

  return (
    <AppBar className={classes.NavBar}>
      {mobileView ? viewMobile() : desktopView()}
    </AppBar>
  );
};

export default ResponsiveAppBar;
